import React, { useContext, useEffect, useState } from "react";

import "./../scss/Page.scss";

import ClassWrapper from "./factory/ClassWrapper";
import ShoppingCart from "./ShoppingCart";

import { ShoppingCartContext } from "./ShoppingCartContext";
import { HeaderContext } from "./header/HeaderContext";

import {
	getAPIData,
	getL,
	clearLC,
	clearL,
	getUrlFromBaseAPI,
	generateRandomID,
	setLC,
	getLC,
	getEnvVar,
	getS,
	setS
} from "../../util/lib";
import { gaPageView, fbPixelPageView } from "../../util/trackerUtil";
import WidgetFactory, { PAGE } from "./factory/WidgetFactory";
import { RouterContext } from "./router/RouterContext";
import { parsePlayerBeacons, processBeacons } from "../../util/beaconUtils";
import { VT_URL_PARAMS } from "../../util/universalConstans";
import { convertQueryParamsToObject, getSEOData } from "../../util/apiUtils";
import SEO from "./seo/SEO";
import EPGProvider from "./epgV2/EPGProvider";
import VideoPlayerStore from "./videoPlayer/VideoPlayerStore";
import SlideInProvider from "../../contexts/SlideinContext/SlideinProvider";
import EPGProviderV3 from "./epgV3/EPGProvider";
import FooterStore from "./hybrid/footer/FooterStore";

//TEUX DEUX ~ remove hard code
// import { MessageWidget_V } from '../../demoData/quadrado/MessageWidget_V';

//TEUX DEUX ~ remove hard code
// const pageData = MessageWidget_V;

const ORDER_ID = "order_id",
	SHIPPING_ID = "shipping_id",
	COUNTRY_ID = "country_id",
	STATE_ID = "state_id",
	SIMPLY_SOUTH_URL = "https://www.simplysouth.tv",
	ADTNL_POST_DATA_KEYS = [ORDER_ID, SHIPPING_ID, COUNTRY_ID, STATE_ID],
	HYBRID_SCROLL_WIDGET = "HybridScrollWidget",
	HYBRID_GRID_LISTING = "HybridGridListing",
	SESSION_TRACKER = "session-tracker",
	URL_PROXY = "url-proxy",
	DEFAULT_SEO = {
		title: getEnvVar("REACT_APP_TITLE"),
		description: getEnvVar("REACT_APP_DESCRIPTION"),
		keywords: getEnvVar("REACT_APP_KEYWORDS"),
		image: `${getEnvVar("REACT_APP_SITE_DOMAIN")}/site_img.png`,
	},
	scrollWidgetProcessor = (widgets = []) => {
		const scrollWidget = { type: HYBRID_SCROLL_WIDGET },
			widgetsProcessed = Array.isArray(widgets) ? widgets : [],
			widgetsFiltered = widgetsProcessed.filter((widget = {}) => {
				return (
					widget.type === HYBRID_SCROLL_WIDGET ||
					widget.type === HYBRID_GRID_LISTING
				);
			}),
			rawCards = widgetsFiltered.map((widget = {}) => {
				const type = widget.type || "";

				if (type === HYBRID_SCROLL_WIDGET) {
					const data = widget.data || {},
						wCards = data.cards || null;

					return wCards && Array.isArray(wCards) && wCards.length
						? wCards[0]
						: null;
				} else if (type === HYBRID_GRID_LISTING) {
					const card = { type: "HybridCard", layout: 2 },
						wData = widget.data || {},
						wMeta = widget.meta || {},
						layout = widget.layout,
						subtitle = wData.subtitle || '',
						subtitleMeta = wMeta.subtitle || '',
						title = wData.title || '',
						titleMata = wMeta.title || {},
						
						cards = wData.cards || {},
						cLayout = widget.layout || null;

					return {
						...card,
						data: { 
							title,
							subtitle,
							list: {
								data: {cards},
								layout //sending layout to the child component (fixing HGlisting)
							}},
						meta: { 
							title: {...titleMata},
							subtitle: {...subtitleMeta}
						},
						cLayout: cLayout || null
					};
				} else {
					return null;
				}
			}),
			cards = rawCards.filter((card = "") => {
				return card && card.type === "HybridCard";
			});
		return [{ ...scrollWidget, data: { cards } }];
	},
	handleRoutingResponse = (response = {}, props = {}, handleAPIResponse = '', setVideoDetails = '') => {
		let urlParamsObj = {};

		const location = props.location || {},
			pathname = location.pathname,
			wLocation = window.location || {},
			pageURL = wLocation.href || SIMPLY_SOUTH_URL + pathname,
			redirectProxy = response.redirect_proxy || {},
			redirectProxyPath = redirectProxy.path || "",
			redirectProxyUrl = redirectProxy.url || "";

		if (redirectProxyPath || redirectProxyUrl) {
			const history = props.history,
				setRoute = props.setRoute || "";

			setS(URL_PROXY, encodeURIComponent(pageURL));

			if (history && history.replace) {
				if (setRoute) {
					setRoute(redirectProxy.route);
				}
				history.replace(redirectProxyPath || redirectProxyUrl);
			} else {
				window.location.href = redirectProxyPath || redirectProxyUrl;
			}
		} else {
			let new_session = false;

			const apiURL = response.api_url + location.search,
				postData = ADTNL_POST_DATA_KEYS.reduce(
					(pd = {}, key = "") => {
						const val = getL(key) || "",
							obj = {};

						if (val) {
							obj[key] = val;
							return { ...pd, ...obj };
						}

						return pd;
					},
					{
						url: pageURL,
					}
				),
				urlParamsStr = getL(VT_URL_PARAMS) || "",
				plan_id = getL("payment_plan_id_2") || getL("payment_plan_id") || "",
				course_id = getL("payment_plan_course_id") || "",
				event_id = getL("payment_plan_event_id") || "",
				video_id = getL("payment_plan_video_id") || "",
				show_id = getL("show_id") || "",
				isUpgradableUser = getL("is_upgradable_user") || false,
				category_guid = getL("category_guid") || "",
				classType = getL("class") || "",
				expert_id = getL("expert_id") || "",
				master_class_id = getL("master_class_id") || "",
				beacons = getUrlFromBaseAPI("beacons") || "",
				urlProxy = getS(URL_PROXY) || "",
				newSession = getS('new_session'),
				last_played_video_id = getL("last_played_video_id");

			if (newSession === 'set') {
				new_session = false;
			} else {
				new_session = true;
			}

			setS('new_session', 'set');

			try {
				urlParamsObj = JSON.parse(urlParamsStr);
			} catch (e) {
				urlParamsObj = {};
				console.log(e);
			}

			if (urlProxy) {
				urlParamsObj[URL_PROXY] = decodeURIComponent(urlProxy);
			}

			clearL(VT_URL_PARAMS);

			if (beacons) {
				processBeacons(parsePlayerBeacons(beacons));
			}

			clearL("is_upgradable_user:", classType);


			//get query params and convert them to post params
			const postParams = convertQueryParamsToObject(window.location.search);

			if (setVideoDetails) {
				console.log('SETVID + app 0', setVideoDetails);
				setVideoDetails({});
			}

			// handleAPIResponse(pageData); //TEUX DEUX ~ remove hard code

			getAPIData(
				apiURL,
				{
					...postData,
					...urlParamsObj,
					last_played_video_id,
					new_session,
					plan_id,
					video_id,
					course_id,
					event_id,
					category_guid,
					expert_id,
					master_class_id,
					class: classType,
					content_show_id: show_id,
					deferred_load_supported: true,
					is_upgradable_user: isUpgradableUser,
					...postParams
				},
				(resp) => {
					if (handleAPIResponse) {
						handleAPIResponse(resp)
					}
				}
			);
		}
	},
	Page = (props = {}) => {
		const isWebview = getL("webview"),
			// [hideHeader] = useState(isWebview ? true : false),
			[seo, setSeo] = useState({ ...DEFAULT_SEO }),
			[pageTitle, setPageTitle] = useState(DEFAULT_SEO.pageTitle || ''),
			[nextAPI, setNextAPI] = useState(''),
			[nextAPIParams, setNextAPIParams] = useState({}),
			[dataLoaded, setDataLoaded] = useState(false),
			[pageCount, setPageCount] = useState(0),
			[pageData, setPageData] = useState(''),
			[meta, setMeta] = useState({}),
			[widgets, setWidgets] = useState([]),
			[widgetsRaw, setWidgetsRaw] = useState([]),
			[canLoadHeader, setCanLoadHeader] = useState(false),
			[dontRenderPage, setDontRenderPage] = useState(false),
			{ route: embededRoute = '', setRoute = '' } = useContext(RouterContext),
			{ seoApi = "", className: themeClass = '', settingsData = {}, location = {}, homeAction = false, setHasScrollWidgetFooter = false } = props,
			addFont = settingsData.accent_font || "",
			pathname = location.pathname,
			wLocation = window.location || {},
			url = wLocation.href || SIMPLY_SOUTH_URL + pathname,
			{ setVideoDetails = '', setEpgData = '' } = useContext(VideoPlayerStore),
			// { setPageMeta, setFirstPageLoaded, setPageLoading, setPath, load, hide } = useContext(HeaderContext);
			{ setPageMeta, setFirstPageLoaded, setPageLoading, setPath, load, hide, hideHeader, setHasScrollWidget } = useContext(HeaderContext),
			[headerHide, setHeaderHide] = useState(isWebview || dontRenderPage ? true : false),
			{ hideFooter } = useContext(FooterStore),
			[splashScreen, setSplashScreen] = useState(null),
			[pathNameOverride, setPathNameOverride] = useState(0);

		useEffect(() => {
			const pathnameL = pathname || "/",
				newSession = getS('new_session');
			console.log(homeAction, newSession, pathnameL, "awidgaiywgdaiywgd")
			if (newSession !== 'set' && pathnameL === '/' && homeAction) {
				console.log(homeAction, newSession, pathnameL, "awidgaiywgdaiywgd2")
				const { actionURL = {}, route = '' } = homeAction,
					aPath = actionURL ? actionURL.path : '',
					routeToSet = route || actionURL.route || '',
					history = props.history;

				if (aPath) {
					if (setRoute) {
						setRoute(routeToSet ? {
							...routeToSet,
							splashScreen: props.splashScreen
						} : '');
					}

					if (history && history.push) {
						history.push(aPath);
					} else {
						window.location = aPath;
					}
				} else {
					setPathNameOverride(pathname);
				}
			} else {
				setPathNameOverride(pathname);
			}
		}, [pathname]);

		useEffect(() => {
			if (pathNameOverride !== 0 && seoApi) {
				getSEOData({
					route: url,
					seoApi,
				})
					.then((seoData) => {
						const { seo = "" } = seoData;
						if (seo) {
							setSeo(seo);
						} else {
							setSeo({ ...DEFAULT_SEO });
						}
					})
					.catch((exception) => {
						console.log("getSeoData failed:", exception);
						setSeo({ ...DEFAULT_SEO });
					});
			} else {
				setSeo({ ...DEFAULT_SEO });
			}
		}, [pathNameOverride]);

		useEffect(() => {
			if (pathNameOverride !== 0) {
				if (
					pathNameOverride.indexOf("/payment") === -1 &&
					pathNameOverride.indexOf("/plans") === -1 &&
					pathNameOverride.indexOf("/login") === -1 &&
					pathNameOverride.indexOf("/register") === -1
				) {
					clearL("payment_plan_id");
					clearL("payment_plan_video_id");
					clearL("payment_plan_id_2"); // Arun - payment_plan_id_2 is an alternative to store plan id for Master Class
					clearL("payment_plan_course_id");
					clearL("payment_plan_event_id");
					clearL("start_date");
					clearL("lastVideoLocation");
					clearL("bundle_ids");
					clearL("plan_id");
					clearL("planid");
					clearL("svod_plan_id");
					clearL("deviceConnectFlow");
				}
				if (
					pathNameOverride.indexOf("/login") === -1 &&
					pathNameOverride.indexOf("/deleteMyAccount") === -1 &&
					pathNameOverride.indexOf("/register") === -1
				) {
					clearL("postLoginRedirectUrl");
				}
				if (pathNameOverride.indexOf("/watch") === -1) {
					clearL("expert_id");
					clearL("master_class_id");
				}
				if (pathNameOverride.indexOf("/category") === -1) {
					clearL("class");
				}
				if (pathNameOverride.indexOf("/category") === -1) {
					clearL("category_guid");
				}
				if (pathNameOverride.indexOf("/login") === -1) {
					clearL("destination_page_path");
				}

				if (pathNameOverride.indexOf("/paymentConfirmation") === -1) {
					clearL("gift_card_id");
					clearL("is_gift_card");
				}

				setNextAPI('');
				setNextAPIParams({});
				setDataLoaded(false);
				setPageCount(pageCount + 1);

				if (setEpgData) {
					setEpgData('');
				}

				console.log('weeeee eee', embededRoute);

				if (embededRoute) {
					if (setRoute) {
						setRoute(); // remove embededRoute
					}

					setSplashScreen(embededRoute.splashScreen || null);

					handleRoutingResponse(embededRoute, props, (pageDataR = '') => {
						setPageData(pageDataR);
					}, setVideoDetails);
					// this.handleRoutingResponse(embededRoute);
				} else {
					const sessionTracker = getLC(SESSION_TRACKER) || false; // session will last for an hour

					if (!sessionTracker) {
						setLC(SESSION_TRACKER, generateRandomID(10), 1 * 60 * 1); // one hours cache only
					}

					getAPIData(
						props.routingAPI,
						{
							ns: props.canProceedWithAuthCallout && !sessionTracker,
							url,
						},
						(resp) => {
							handleRoutingResponse(resp, props, (pageDataR = '') => {
								console.log(pageDataR);
								setPageData(pageDataR);
							}, setVideoDetails);
						}
					);
				}
			}
		}, [pathNameOverride]);

		useEffect(() => {
			if (pageData && pageData.data) {
				let renderTimeout = 0;
				const { data = {}, meta: metaL = {} } = pageData,
					pathname = location.pathname || "/",
					{ widgets: widgetsRaw = [], title: pageTitleL = "" } = data;

				setDontRenderPage(metaL.donotRender === true);

				gaPageView(pathname);
				fbPixelPageView(pathname);

				ADTNL_POST_DATA_KEYS.forEach((key = "") => {
					clearLC(key);
				});

				window.scrollTo(0, 0); //the page should scroll to top before rendering page

				setWidgets([]);
				setWidgetsRaw([]);
				setNextAPIParams(data.next_url_params || {});

				setHasScrollWidget(metaL.scrollWidgetPage);
				if(setHasScrollWidgetFooter) {
					setHasScrollWidgetFooter(metaL.scrollWidgetPage);
				}

				renderTimeout = setTimeout(() => {
					setMeta(metaL);
					setNextAPI(metaL.isDeferredLoadedYN && data.next_url ? data.next_url : "");
					setCanLoadHeader(true);
					setDataLoaded(true);
					setPageTitle(pageTitleL);
					setWidgets(metaL.scrollWidgetPage ? scrollWidgetProcessor(widgetsRaw) : widgetsRaw);
					setWidgetsRaw(widgetsRaw);
				}, 200);

				return () => {
					clearTimeout(renderTimeout);
					renderTimeout = 0;
				}
			}
		}, [pageData]);

		useEffect(() => {
			setHeaderHide(isWebview || dontRenderPage);
		}, [dontRenderPage]);

		useEffect(() => {
			if (!headerHide) {
				if (dataLoaded) {
					setFirstPageLoaded(true);
					hideHeader(false);
					hideFooter(false);
					if (canLoadHeader) {
						setCanLoadHeader(false);
						hide();
						setPageMeta(meta);
						load();
						setPageLoading(false);
					}

				} else {
					setPageLoading(true);
				}
			} else {
				hideHeader(true);
				hideFooter(true);
				setPageLoading(false);
			}

		}, [headerHide, dataLoaded, dontRenderPage]);

		return <>
			{seo && <SEO seo={{ pageTitle, ...seo }} />}
			<ShoppingCartContext.Consumer>
				{({ shopEnabled }) => {
					return shopEnabled ? <ShoppingCart /> : "";
				}}
			</ShoppingCartContext.Consumer>
			<div className={`site-body ${themeClass}`}>
				<EPGProvider>
					<EPGProviderV3>
						<SlideInProvider>
							<WidgetFactory
								splashScreen={props.splashScreen}
								pageCount={pageCount}
								location={location}
								nextAPI={nextAPI}
								nextAPIParams={nextAPIParams}
								widgets={widgets}
								dontRenderPage={dontRenderPage}
								onDataChange={(nextWidgets, nextAPIL = "", nextAPIParamsL = {}) => {
									const widgetsRawL = [...(widgetsRaw || []), ...nextWidgets],
										metaL = meta || {};

									setWidgets(metaL.scrollWidgetPage ? scrollWidgetProcessor(widgetsRawL) : [...(widgets || []), ...nextWidgets]);
									setWidgetsRaw(widgetsRawL);
									setNextAPI(nextAPIL);
									setNextAPIParams(nextAPIParamsL);
								}}
								addFont={addFont}
								parent={PAGE}
								meta={meta}
							/>
						</SlideInProvider>
					</EPGProviderV3>
				</EPGProvider>
			</div>
		</>;
	};

export default ClassWrapper(Page);

