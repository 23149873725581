import React from 'react'
import { headerFactoryMap } from './HeaderFactoryMap';

const HeaderFactory = ({ data: wData = {}, activePage = '/', pageMeta = {} , hasScrollWidget = false}) => {
	const { layout = 1, data = {}, meta = {} } = wData,
		HeaderComp = headerFactoryMap[layout];

	return (<HeaderComp pageMeta={pageMeta} data = {data} meta = {meta} layout = {layout} activePage={activePage} hasScrollWidget={hasScrollWidget}/>);
}

export default HeaderFactory;
