import { debugLog } from "./logUtils";

export const isValidDate = (dateJSON, yearAs) => {
	const { date, month } = dateJSON,
		year = dateJSON.year || yearAs;
	const dateObj = new Date(`${month}/${date}/${year}`),
		newDate = dateObj.getDate().toString().padStart(2, 0),
		newMonth = (dateObj.getMonth() + 1).toString().padStart(2, 0),
		newYear = dateObj.getFullYear();

	return newDate === date && newMonth === month && newYear === year;
}

export const checkValidAge = (dateObj, minumumAge) => {
	const { date = '', month = '', year = '' } = dateObj;

	if (date && month && year) {
		const today = new Date(),
			birthDate = new Date(`${month}/${date}/${year}`),
			yearDiff = today.getFullYear() - birthDate.getFullYear(),
			monthDiff = today.getMonth() - birthDate.getMonth();

		debugLog(yearDiff, monthDiff);

		if (yearDiff > minumumAge) {
			return true;
		}

		if (yearDiff < minumumAge) {
			return false;
		}

		if (yearDiff === minumumAge) {
			if (monthDiff > 0) {
				return false;
			}

			if (monthDiff === 0) {
				if (today.getDate() < birthDate.getDate()) {
					return false;
				}
			}

			return true;
		}
	}

	return false;
}

export const convertEpochToFormattedDate = (epoch) => {

	const date = new Date(epoch * 1000); // Multiply by 1000 to convert seconds to milliseconds
  
	// Extract individual components
	const day = String(date.getDate()).padStart(2, '0');
	const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
	const month = months[date.getMonth()];
	let hours = date.getHours();
	const minutes = String(date.getMinutes()).padStart(2, '0');
	const period = hours >= 12 ? 'PM' : 'AM';
  
	// Convert hours from 24-hour to 12-hour format
	hours = hours % 12 || 12;

	return `${day}-${month} ${String(hours).padStart(2, '0')}.${minutes} ${period}`;
  }